<template>
  <div id="gallery-wrapper">
    <figure v-for="(image, index) in images"
            :key="index">
      <img :src="image"
           loading="lazy"
           alt="A Photo"
           @click="() => openImageModal(index)"/>
<!--      <figcaption><a href="#">{{ index }}</a></figcaption>-->
    </figure>

    <transition name="fade"
                mode="in-out">
      <ImageViewModal v-if="imageModalOpen"
                      ref="imageModal"
                      :images="images"
                      :current-image-index="currentImageIndex"
                      @close="() => toggleImageModal(false)"/>
    </transition>
  </div>
</template>

<script>
  import ImageViewModal from '@/components/ImageViewModal';

  export default {
    name: 'Gallery',

    components: { ImageViewModal },
    // head: {
    //   title: 'Gallery — Adam Chaarawi',
    //   meta: [
    //     {
    //       name: 'description',
    //       id: 'desc',
    //       content: "This is a collection of my headshots, production shots, and photoshoots.",
    //     }
    //   ],
    // },

    data() {
      return {
        imageModalOpen: false,
        currentImageIndex: -1,
        // images: [
        //   require('@/assets/headshot-2-min.jpg'),
        //   require('@/assets/_RJ_9697.jpg'),
        //   require('@/assets/_RJ_9830.jpg'),
        //   require('@/assets/EITW Prod Shot 1.jpg'),
        //   require('@/assets/_RJ_9945.jpg'),
        //   require('@/assets/The Census Prod Shot 1.jpg'),
        //   require('@/assets/_RJ_9712.jpg'),
        //   require('@/assets/_RJ_0070.jpg'),
        //   require('@/assets/eMotions Prod Shot 1.jpg'),
        //   require('@/assets/Killer Service Shot 1.jpg'),
        //   require('@/assets/Hyper Real Prod Shot 1.jpg'),
        //   require('@/assets/headshot-3-min.jpg'),
        //   require('@/assets/_RJ_9587.jpg'),
        //   require('@/assets/EITW Prod Shot 2.jpg'),
        //   require('@/assets/1Z8A0068.jpg'),
        //   require('@/assets/EITW Prod Shot 3.jpg'),
        //   require('@/assets/1Z8A0178.jpg'),
        // ],
        images: [
          'https://res.cloudinary.com/dt7fbptp2/image/upload/v1703786746/personal/headshot-2-min_xogfln.jpg',
          'https://res.cloudinary.com/dt7fbptp2/image/upload/v1703786732/personal/_RJ_9697_hjy1xq.jpg',
          'https://res.cloudinary.com/dt7fbptp2/image/upload/v1703786687/personal/_RJ_9830_q1ziqw.jpg',
          'https://res.cloudinary.com/dt7fbptp2/image/upload/v1704307421/personal/EITW_Prod_Shot_1_kpjyue.jpg',
          'https://res.cloudinary.com/dt7fbptp2/image/upload/v1703786732/personal/_RJ_9945_d8rxoa.jpg',
          'https://res.cloudinary.com/dt7fbptp2/image/upload/v1704307422/personal/The_Census_Prod_Shot_1_nwyh6d.jpg',
          'https://res.cloudinary.com/dt7fbptp2/image/upload/v1703786688/personal/_RJ_9712_rufubv.jpg',
          'https://res.cloudinary.com/dt7fbptp2/image/upload/v1703786732/personal/_RJ_0070_vrf2tr.jpg',
          'https://res.cloudinary.com/dt7fbptp2/image/upload/v1704307421/personal/eMotions_Prod_Shot_1_xinrso.jpg',
          'https://res.cloudinary.com/dt7fbptp2/image/upload/v1704307421/personal/Killer_Service_Shot_1_regxeh.jpg',
          'https://res.cloudinary.com/dt7fbptp2/image/upload/v1704307421/personal/Hyper_Real_Prod_Shot_1_uzf0oa.jpg',
          'https://res.cloudinary.com/dt7fbptp2/image/upload/v1703786746/personal/headshot-3-min_p0zm7f.jpg',
          'https://res.cloudinary.com/dt7fbptp2/image/upload/v1703786688/personal/_RJ_9587_tk6x1l.jpg',
          'https://res.cloudinary.com/dt7fbptp2/image/upload/v1704307421/personal/EITW_Prod_Shot_2_sq6xeq.jpg',
          'https://res.cloudinary.com/dt7fbptp2/image/upload/v1703786771/personal/1Z8A0068_igd9yi.jpg',
          'https://res.cloudinary.com/dt7fbptp2/image/upload/v1704307421/personal/EITW_Prod_Shot_3_rfelpy.jpg',
          'https://res.cloudinary.com/dt7fbptp2/image/upload/v1703786772/personal/1Z8A0178_jwv1v1.jpg',
        ],
      }
    },

    watch: {
      imageModalOpen(isOpen) {
        document.getElementsByTagName('body')[0].style = isOpen ? 'overflow: hidden' : 'overflow: auto';
      }
    },

    methods: {
      openImageModal(index) {
        this.currentImageIndex = index;
        this.imageModalOpen = true;
      },

      toggleImageModal(open) {
        this.imageModalOpen = open;
      }
    }
  }
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  #gallery-wrapper {
    column-count: 4;
    column-gap: 2vw;
    min-height: 50vh;
    width: 100%;
  }

  img {
    max-width: 100%;
    display: block;
  }

  figure {
    margin: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: 10px;
    break-inside: avoid;
  }

  figure > img {
    grid-row: 1 / -1;
    grid-column: 1;
    cursor: pointer;
  }

  figure > img:hover {
    opacity: 0.8;
  }

  figure a {
    color: black;
    text-decoration: none;
  }

  @media only screen and (max-width: 1000px) {
    #gallery-wrapper {
      column-count: 3;
      column-gap: 2vw;
    }
  }

  @media only screen and (max-width: 800px) {
    #gallery-wrapper {
      column-count: 2;
      column-gap: 2vw;
    }
  }
</style>