<template>
  <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
        <slot name="header">
        </slot>

        <div class="btn-close"
             @click="close">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </div>
      </header>

      <section class="modal-body">
        <slot name="body">
        </slot>
      </section>

      <footer class="modal-footer">
        <slot name="footer">
        </slot>
      </footer>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Modal',

    methods: {
      close() {
        this.$emit('close');
      },
    },
  }
</script>

<style scoped>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
  }

  .modal {
    /*box-shadow: 2px 2px 20px 1px;*/
    display: flex;
    flex-direction: column;
    max-width: 1340px;
    padding: 0 2.5em;
    margin: 0 auto;
  }

  .modal-header,
  .modal-footer {
    padding: 2.5em 0;
    /*width: 100%;*/
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .modal-header {
    position: relative;
  }

  .modal-body {
    position: relative;
    flex-grow: 1;
  }

  /deep/ .menu-btn .icon-bar {
    background: white;
  }

  .btn-close {
    height: 26px;
    width: 26px;
    position: relative;
    cursor: pointer;
    pointer-events: auto;
    touch-action: manipulation;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: opacity .2s;
  }

  .btn-close:hover {
    opacity: 0.5;
  }
  .btn-close .icon-bar {
    display: block;
    position: relative;
    top: 13px;
    width: 26px;
    height: 1px;
    background: white;
    transition: .3s;
    opacity: 1;
  }
  .btn-close .icon-bar:first-child {
    transform: rotate(45deg);
  }
  .btn-close .icon-bar:last-child {
    transform: rotate(-45deg);
  }
</style>