<template>
  <Modal @close="$emit('close')">
    <template v-slot:body>
      <div class="img-view-wrapper">
        <button class="btn-next"
                type="button"
                :disabled="imageIdx <= 0"
                style="margin-right: 30px"
                @click="showPrevImage">
          <i class="fa fa-angle-left"/>
        </button>

        <div class="img-wrapper">
          <img :src="images[imageIdx]"
               alt="A Photo"/>
        </div>

        <button class="btn-next"
                type="button"
                :disabled="imageIdx >= images.length - 1"
                style="margin-left: 30px"
                @click="showNextImage">
          <i class="fa fa-angle-right"/>
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/Modal';

  export default {
    name: 'ImageViewModal',

    components: { Modal },

    props: {
      images: {
        type: Array,
        required: true
      },
      currentImageIndex: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        imageIdx: this.currentImageIndex,
      }
    },

    created() {
      window.addEventListener('keydown', (e) => {
        switch (e.key) {
          case 'ArrowLeft':
            this.showPrevImage();
            break;
          case 'ArrowRight':
            this.showNextImage();
            break;
          case 'Escape':
            this.close();
            break;
          default:
            break;
        }
      });
    },

    methods: {
      close() {
        this.$emit('close');
      },

      showPrevImage() {
        if (this.imageIdx > 0) {
          this.imageIdx--;
        }
      },

      showNextImage() {
        if (this.imageIdx < this.images.length - 1) {
          this.imageIdx++;
        }
      },
    }
  }
</script>

<style scoped>
  /deep/ .modal {
    height: 100%;
    width: 100%;
  }

  .img-view-wrapper {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
  }

  .btn-next {
    background: transparent;
    box-shadow: none;
    border: none;
    color: white;
    font-size: 35px;
    padding: 5px;
    height: fit-content;
    margin: auto 0;
    cursor: pointer;
    transition: opacity .2s;
  }

  .btn-next:hover {
    opacity: 0.5;
  }

  .btn-next:disabled {
    opacity: 0.5;
    cursor: default;
  }

  .img-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .img-wrapper img {
    display: block;
    max-width: 100%;
    height: auto;
    max-height: min(550px, calc(100vh - 190px));
    margin: auto;
    object-fit: contain;
  }
</style>